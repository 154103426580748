<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Gestión de secciones
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar, crear, actualizar, eliminar y cambiar
              el estado de todas las secciones.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="showMdlCreate"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg>
            </span>
            Crear sección
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-9 col-xl-8">
              <div class="row align-items-center">
                <div class="col-md-4 my-2 my-md-0">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="sections.search"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="sections.headers"
            :items="sections.data"
            :search="sections.search"
            :items-per-page="10"
            :loading="sections.isLoading"
          >
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="existInArray('Editar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        selectSection(item);
                        showMdlUpdate();
                      "
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-if="existInArray('Eliminar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectSection(item);
                        showMdlDelete();
                      "
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template
                v-if="existInArray('Activar/Desactivar', currentPageActions)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectSection(item);
                        showMdlChangeStatus();
                      "
                      :color="
                        item.status == 1
                          ? 'cyan darken-3'
                          : 'blue-grey darken-1'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        item.status == 1
                          ? "mdi-eye-outline"
                          : "mdi-eye-off-outline"
                      }}
                    </v-icon>
                  </template>
                  <span>
                    {{ item.status == 1 ? "Desactivar" : "Activar" }}
                  </span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <CreateSectionModal
      ref="mdlCreate"
      @reload-data="loadSections"
      :groups="groups"
    ></CreateSectionModal>

    <UpdateSectionModal
      ref="mdlUpdate"
      @reload-data="loadSections"
      @clean-slection="unselectSection"
      :section="selectedSection"
      :groups="groups"
    ></UpdateSectionModal>

    <DeleteBaseModal ref="mdlDelete" @delete="deleteSection">
      <template v-slot:title>¿Desea eliminar esta sección?</template>
      <template v-slot:subject>
        la sección
        <span class="font-weight-bolder">{{ selectedSection.name }}</span>
      </template>
    </DeleteBaseModal>

    <ChangeStatusBaseModal
      ref="mdlChangeStatus"
      :selected="selectedSection"
      @change-status="changeStatusSection"
    >
      <template v-slot:title>
        ¿Deseas cambiar el estado de esta sección?
      </template>
      <template v-slot:subject>
        la sección
        <span class="font-weight-bolder">{{ selectedSection.name }}</span>
      </template>
    </ChangeStatusBaseModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import sectionRepository from "@/repositories/sectionRepository";
import groupRepository from "@/repositories/groupRepository";
import CreateSectionModal from "@/components/modals/sections/CreateSectionModal.vue";
import UpdateSectionModal from "@/components/modals/sections/UpdateSectionModal.vue";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "Sections",
  title: "Secciones | GE ITR",
  components: {
    CreateSectionModal,
    UpdateSectionModal,
    DeleteBaseModal,
    ChangeStatusBaseModal,
  },
  data() {
    return {
      sections: {
        headers: [
          { text: "Sección", value: "name" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
      },
      selectedSection: {},
      groups: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Secciones", route: "sections" },
    ]);
    this.loadSections();
    this.getGroups();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Secciones");
  },
  methods: {
    selectSection(obj) {
      // Creating a non reactive copie of data
      this.selectedSection = { id: obj.id, name: obj.name, groups: [] };
      obj.section_group.forEach((item) => {
        this.selectedSection.groups.push(item.group_id);
      });
    },
    unselectSection() {
      this.selectedSection = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    showMdlChangeStatus() {
      this.$refs.mdlChangeStatus.toggleModal();
    },
    loadSections() {
      this.sections.isLoading = true;
      sectionRepository
        .getAllSections()
        .then((response) => {
          this.sections.data = response.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.sections.isLoading = false;
        });
    },
    deleteSection() {
      sectionRepository
        .deleteSection(this.selectedSection.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadSections();
          }
        })
        .catch(() => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible eliminar el registro seleccionado en estos momentos 🙁`,
          });
        });
    },
    changeStatusSection() {
      sectionRepository
        .changeStatusSection(this.selectedSection.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadSections();
          }
        })
        .catch(() => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible cambiar el estado del registro seleccionado en estos momentos 🙁`,
          });
        });
    },
    getGroups() {
      groupRepository
        .getAllGroups()
        .then(({ data }) => {
          this.groups = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
