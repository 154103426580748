import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSections = () => {
  return ApiService.get("sections");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSectionWithSectionGroups = () => {
  return ApiService.get("sections/section_groups");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getSection = (id) => {
  return ApiService.get("sections", id);
};

/**
 * POST request to create a new section
 * @param payload
 * @returns {*}
 */
export const createSection = (payload) => {
  return ApiService.post("sections", payload);
};

/**
 * PUT request to update a specific section
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateSection = (id, payload) => {
  return ApiService.update("sections", id, payload);
};

/**
 * DELETE request to delete the specified section
 * @param id
 * @returns {*}
 */
export const deleteSection = (id) => {
  return ApiService.delete(`sections/${id}`);
};

/**
 * GET request to change status of the specified section
 * @param id
 * @returns {*}
 */
export const changeStatusSection = (id) => {
  return ApiService.get("sections/status", id);
};

export default {
  getAllSections,
  getAllSectionWithSectionGroups,
  getSection,
  createSection,
  updateSection,
  deleteSection,
  changeStatusSection,
};
