<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="amber" class="elevation-0 px-3">
          <span class="headline">Actualizar sección</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pb-0">
                <form-group
                  name="nombre de la sección"
                  :validator="$v.section.name"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Sección"
                    placeholder="Ingresa el nombre de la sección"
                    required
                    outlined
                    v-model="section.name"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-body-1"
                  >Selecciona los grupos que conformarán la sección</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-for="group in groups"
                  :key="group.id"
                  v-model="section.groups"
                  :label="group.name"
                  :value="group.id"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="amber"
            dark
            depressed
            :loading="isLoading"
            @click="submit"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import sectionRepository from "@/repositories/sectionRepository";

export default {
  name: "UpdateSectionModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  props: {
    section: {
      type: Object,
    },
    groups: {
      type: Array,
    },
  },
  methods: {
    update() {
      this.isLoading = true;
      // const sectionData = {
      //   name: this.section.name,
      //   groups: this.selectedGroups,
      // };
      sectionRepository
        .updateSection(this.section.id, this.section)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.$emit("clean-selection");
            this.closeModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible actualizar el registro 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
    },
  },
  validations: {
    section: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
