import ApiService from "@/core/services/api.service";

let baseURL;

if (process.env.REPORTS_APP_ENV === "production") {
  baseURL = process.env.REPORTS_API_URL;
} else if (process.env.REPORTS_APP_ENV === "development") {
  baseURL = process.env.REPORTS_DEV_API_URL;
} else {
  baseURL = process.env.REPORTS_LOCAL_API_URL;
}

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllGroups = () => {
  return ApiService.get("groups");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getGroup = (id) => {
  return ApiService.get("groups", id);
};

/**
 * POST request to create a new group
 * @param payload
 * @returns {*}
 */
export const createGroup = (payload) => {
  return ApiService.post("groups", payload);
};

/**
 * PUT request to update a specific group
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateGroup = (id, payload) => {
  return ApiService.update("groups", id, payload);
};

/**
 * DELETE request to delete the specified group
 * @param id
 * @returns {*}
 */
export const deleteGroup = (id) => {
  return ApiService.delete(`groups/${id}`);
};

/**
 * GET request to change status of the specified group
 * @param id
 * @returns {*}
 */
export const changeStatusGroup = (id) => {
  return ApiService.get("groups/status", id);
};

/**
 * GET request to change status of the specified group
 * @param id
 * @returns {*}
 */
export const getStudentsProfilePicturesByAcademicGroup = (
  grade_section_group_id
) => {
  return ApiService.get(
    `${baseURL}/students/grade-section-group/${grade_section_group_id}`
  );
};

/**
 * GET request to change status of the specified group
 * @param id
 * @returns {*}
 */
export const getStudentsProfilePicturesByTechnicalGroup = (
  grade_speciality_group_id
) => {
  return ApiService.get(
    `${baseURL}/students/grade-speciality-group/${grade_speciality_group_id}`
  );
};

export default {
  getAllGroups,
  getGroup,
  createGroup,
  updateGroup,
  deleteGroup,
  changeStatusGroup,
  getStudentsProfilePicturesByTechnicalGroup,
  getStudentsProfilePicturesByAcademicGroup,
};
